import { defineNuxtRouteMiddleware, navigateTo, useCookie } from '#app';
import { sessionTokenKey } from '~/types/session';

export default defineNuxtRouteMiddleware(async () => {
  const cookie = useCookie(sessionTokenKey);

  if (!cookie.value) {
    return navigateTo('/login');
  }
});
